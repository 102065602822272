var Accordion = function($element, options){
    this.element = $element;

    this.defaults = {
        singleActiveItem: false,
        activeIndex: undefined
    };
    this.options = jQuery.extend({}, this.defaults, options);

    this.items = this.element.find('> [data-role="accordion-item"]');

    if (this.items.length) {
        this.init();
    }
};

Accordion.prototype = {
    defaults: {},

    init: function ()
    {
        var _self = this;

        this.items.children('[data-role="accordion-title"]').on('click', function() {
            _self.activate($(this).closest('[data-role="accordion-item"]').index());
        });

        if (this.options.activeIndex != undefined) this.activate(this.options.activeIndex);
    },

    activate: function(index) {

        var _self = this;
        var act = this.items.get(index);

        if (act) {
            if (this.options.singleActiveItem) {
                this.element.find('.active').removeClass('active');
                setTimeout(function() {
                    $(act).toggleClass('active').children('[data-role="accordion-content"]').slideToggle();
                }, 500);
            }
            else {
                $(act).toggleClass('active').children('[data-role="accordion-content"]').slideToggle()
            }
        }
    }
};

(function(wnd) {
    function JobForm(form, params) {
        try {
            if (!form) {
                throw 'Form is required';
            }

            this.form = form;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    JobForm.prototype = {
        type: 'register',
        form: undefined,
        button: undefined,
        sended: false,
        user_id: undefined,
        texts: {

        },
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.button = this.form.find('[data-purpose="send-button"]');
        },
        addDOMEvents: function() {
            var _self = this;
            if (this.button) {
                this.button.click(function(){
                    _self.sendForm();
                });
            }

            if (this.getType() == 'login') {
                this.form.find('.field').keypress(function(e){
                    if (e.keyCode == 13) {
                        _self.sendForm();
                    }
                });
            }
            
            if (this.getType() == 'modify') {
                this.form.find('.fileupload').each(function(ind, el){
                    var th = $(el);
                    
                    var input = th.find('.input');
                    var inputName = input.attr('name');
                    var progressbar = th.find('.progress');
                    var uploaded = th.find('.file-uploaded');
                    var feedback = th.find('.upload-feedback');
                    
                    input.fileupload({
                        dataType:'json',
                        url:ajax_controller+'fileupload/'+inputName,
                        disableImagePreview: true,
                        add: function(e, data) {
                            th.data('filename', data.files[0].name);
                            if (progressbar) {
                                progressbar.children('.bar').css('width', 0);
                                progressbar.show();
                            }
                            data.submit();
                        },
                        done: function(e, data) {
                            var id = _self.getUserId();

                            $.each(data.result[inputName], function(index, file){
                                if (file.error) {
                                    showMessage(file.error, 'error');
                                } else {
                                    th.data('filename', file.name);
                                    var uploadedName = th.data('filename');
                                    
                                    var message = _self.getText('fileupload_success')+' <strong>'+uploadedName+'</strong>';
                                    $.ajax({
                                        url:ajax_controller+'save_upload',
                                        dataType:'json',
                                        type:'post',
                                        data: {
                                            id: id,
                                            field: inputName,
                                            value: th.data('filename'),
                                            
                                        },
                                        success: function(response) {
                                            if (response) {
                                                if (!response.success) {
                                                    showMessage(response.message, 'error');
                                                } else {
                                                    if (!th.hasClass('image-upload')) {
                                                        var link = $('<a />', {href: file.url, target: '_blank'}).text(th.data('filename'));
                                                        uploaded.find('.file-name').empty().append(link); 
                                                    }
                                                    else {
                                                        th.find('.file-preview').append($('<img />', {src: file.url}) );
                                                    }
                                                    th.addClass('upload-complete');
                                                }
                                            }
                                        }
                                    });
                                }
                            });
                        },
                        fail: function(e, data) {
                            console.log(data);
                        },
                        progress: function(e, data) {
                            var progress = parseInt(data.loaded / data.totl * 100, 10);
                            progressbar.children('.bar').css('width', progress+'%');
                        }
                    });

                    th.find('.file-delete a').click(function() {
                        var id = _self.getUserId();
            
                        $.post(ajax_controller + 'filedelete', {id: id, field: inputName, file: th.data('filename')}, function(ret) {
                            if (ret.success) {
                                th.removeClass('upload-complete');
                                th.find('.file-preview img').remove();
                            }
                            showMessage(ret.message, ret.success ? 'success' : 'error');
                        }, 'json');
                    });
                });
            }
        },
        setParams: function(params) {
            if (params.type) this.setType(params.type);
            if (params.user_id) this.setUserId(params.user_id);
            if (params.texts) this.setTexts(params.texts);
        },
        setType: function(type) {
            this.type = type;
        },
        getType: function() {
            return this.type;
        },
        setUserId: function(user_id) {
            this.user_id = user_id;
        },
        getUserId: function() {
            return this.user_id;
        },
        sendForm: function() {
            var _self = this;

            if (!this.sended) {
                this.sended = true;

                $.ajax({
                    url:this.form.attr('action'),
                    dataType:'json',
                    type:'post',
                    data:this.form.serialize(),
                    success: function(response) {
                        if (response.success) {
                            if (response.message) {
                                showMessage(response.message, 'success');
                                if (typeof response.redirect !== 'undefined') {

                                    setTimeout(function() {
                                        if (response.redirect && response.redirect.trim() == '') {
                                            document.location.href = response.redirect;
                                        } else {
                                            document.location.reload();
                                        }
                                    }, 2000)
                                    
                                }
                            }
                        } else {
                            if (typeof response.errors !== 'undefined') {
                                $.each(response.errors, function(name, value){
                                    var item = $('[name="'+name+'"]');
                                    if (item.length > 0) {
                                        item.addClass('has-error');

                                        if (item.is('select')) {
                                            item.parent().addClass('has-error');
                                        }
                                    }

                                    showMessage(value, 'error');
                                });
                            }

                            setTimeout(function(){
                                _self.sended = false;
                            }, 500);
                        }
                    } 
                });
            }
        },
        setTexts: function(texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        getText: function(key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        }
    };

    wnd.JobForm = JobForm;
})(window);
